@import 'lib';

.flex {
  display: flex;
  &:not(.flex--column) {
    flex-wrap: wrap;
  }
}

.flex--space-around {
  justify-content: space-around;
}

.flex--space-between {
  justify-content: space-between;
}

.flex--column {
  flex-direction: column;
}

.flex--row {
  flex-direction: row;
}

.flex--horizontal-center {
  justify-content: center;
}

.flex--vertical-center {
  align-items: center;
}

.flex--stretch {
  align-items: stretch;
}

.element--base-size {
  flex-grow: 1;
}

.element--relative {
  position: relative;
}

.element--text-center {
  text-align: center;
}

.element--half-width {
  width: calc(50% - 0.5rem);
}
