@import 'lib';

.page-title--h1 {
  @include font-size(22px);
  margin-top: 1.5rem;

  @include small {
    @include font-size(26px);
  }

  @include medium {
    @include font-size(32px);
    margin-top: 2.5rem;
  }

  @include wide {
    @include font-size(38px);
  }
}

.page-title--h2 {
  @include font-size(20px);
  margin-top: 1rem;

  @include small {
    @include font-size(24px);
  }

  @include medium {
    @include font-size(30px);
    margin-top: 2rem;
  }

  @include wide {
    @include font-size(36px);
  }
}

.page-title--h3 {
  @include font-size(18px);
  margin-top: 1rem;

  @include small {
    @include font-size(22px);
  }

  @include medium {
    @include font-size(28px);
    margin-top: 1.5rem;
  }

  @include wide {
    @include font-size(34px);
  }
}

.page-title--h4 {
  @include font-size(16px);
  margin-top: 1rem;

  @include small {
    @include font-size(20px);
  }

  @include medium {
    @include font-size(26px);
    margin-top: 1.25rem;
  }

  @include wide {
    @include font-size(32px);
  }
}
