@import 'lib';

// Set all dom nodes to border-box
@if $default-border-box {
  *,
  *:after,
  *:before {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
  }
}

// Use font-smoothing
@if $default-font-smoothing {
  html,
  html a {
    @include fontsmoothing();
  }
}

// Disable outlining
@if $default-disable-outline {
  *:active,
  *:hover {
    outline: 0;
  }
}

// clean menu items
@if $default-clean-menus {
  .menu,
  ul,
  ol,
  dl {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

html {
  font-size: 100%;
  min-height: 100%;
}

body {
  background: $body-background;
  color: $body-color;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

img {
  height: auto;
  max-width: 100%;
  display: block;
}

ul,
ol {
  display: flex;
  flex-direction: column;

  @include medium-small {
    flex-direction: row;
  }

  li {
    &:not(:first-child) {
      @include medium-small {
        margin-left: 1rem;
      }
    }
  }
}
