$base-font-size: 16px;
$base-font-size-rem: 1rem;
$base-font-size-mobile: 14px;
$base-font-size-mobile-rem: 0.875rem;
$base-line-height: 1.375;

$system-font: -apple-system, system-ui, 'Segoe UI', Roboto, Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

$base-font-family: $system-font;

$heading-font-family: $system-font;

$font-size-heading-1: 2.5rem;
$font-size-heading-2: 2rem;
$font-size-heading-3: 1.75rem;
$font-size-heading-4: 1.5rem;
$font-size-heading-5: $base-font-size * 1.125;
