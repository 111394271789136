@import 'lib';

.category-navigation__html-tag {
  scroll-behavior: smooth;

  body {
    padding-bottom: 3.925rem;

    @include wide {
      padding-top: 3.925rem;
      padding-bottom: unset;
    }
  }
}

.category-navigation {
  position: fixed;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  flex-direction: row;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;

  @include wide {
    justify-content: center;
    margin: 0 auto;
    top: 0;
    bottom: auto;
  }
}

.category-navigation__item {
  margin-left: 0 !important;
  border-top: 1px solid $white-dark;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  &:not(:first-child) {
    border-left: 1px solid $white-dark;
  }

  &:last-child {
    @include wide {
      border-bottom: 1px solid $white-dark;
    }
  }

  &:nth-child(odd) {
    background-color: $white-light;

    &:hover,
    &:focus {
      background-color: darken($white-light, 3);
    }
  }

  &:nth-child(even) {
    background-color: $white;

    &:hover,
    &:focus {
      background-color: darken($white, 3);
    }
  }

  @include wide {
    border: 1px solid $white-dark;
    border-top: 0;
    max-width: 7rem;
  }
}

.category-navigation__link {
  @include font-size(18px);
  padding: 1rem 1.5rem;
  color: $black;
  display: block;
  width: 100%;
  text-align: center;

  @include wide {
    @include font-size(20px);
  }
}
