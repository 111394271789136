@import 'lib';

.data-grid__item {
  width: calc(100% / 6);
  white-space: normal;
  text-align: left;
  display: block;
}

.data-grid__item--dns {
  max-width: 3.5rem;
  position: relative;
  z-index: 2;
}

.data-grid__item--time,
.data-grid__item--edit,
.data-grid__item--status {
  max-width: 7rem;
}

.data-grid__item--differential {
  max-width: 7.5rem;
}

.data-grid__item--bib,
.data-grid__item--place {
  max-width: 2.5rem;
}

.data-grid__item--category {
  max-width: 3.5rem;
}

.data-grid__item--name {
  min-width: 10rem;
  width: calc(100% - 23rem);

  @include medium {
    width: calc(45% - 4rem);
  }

  @include wide {
    max-width: calc(100% / 5);
  }

  .club {
    @include font-size(14px);

    &--gray {
      color: $gray;
    }
  }
}

.data-grid__item--hidden-medium {
  display: none;

  @include medium {
    display: unset;
  }
}

.data-grid__item--L {
  width: 40%;
  max-width: 40%;
}

.data-grid__item--XL {
  width: 100%;
}
