@import 'lib';

.container {
  margin: 0 auto;
  max-width: $container;
  position: relative;
  width: 90%;
}

.container--inset {
  padding: 0 1.5rem;
}
