$font-path: '/websocket/font';

@mixin fontello-font-face {
  @font-face {
    font-family: 'fontello';
    src: url('#{$font-path}/fontello.eot?45741650');
    src: url('#{$font-path}/fontello.eot?45741650#iefix')
        format('embedded-opentype'),
      url('#{$font-path}/fontello.woff2?45741650') format('woff2'),
      url('#{$font-path}/fontello.woff?45741650') format('woff'),
      url('#{$font-path}/fontello.ttf?45741650') format('truetype'),
      url('#{$font-path}/fontello.svg?45741650#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}
