@import 'lib';

.data-grid__header {
  .data-grid__row {
    background-color: $blue !important;
    color: $white;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    &:hover,
    &:focus {
      background-color: $blue;
      color: $white;
    }
  }
}
