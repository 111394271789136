@import 'lib';

.input {
  width: auto;
  height: 2rem;
  line-height: 2rem;
  padding: 0 1rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.input__label {
  @include font-size(14px);
  font-family: $heading-font-family;
  margin: 0;
}
