@import 'lib';

.race-name {
  text-transform: uppercase;
  color: $gray;
  letter-spacing: 0.015rem;
  margin-top: -1rem;

  @include font-size(12px);

  @include small {
    @include font-size(14px);
  }

  @include medium {
    @include font-size(16px);
  }

  @include wide {
    @include font-size(18px);
  }
}
