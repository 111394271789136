@import 'lib';

.data-grid__row {
  @media screen and (max-width: 600px) {
    min-width: 35rem;
  }

  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  transition: background-color 0.25s ease, border-color 0.25s ease;

  &:nth-child(odd) {
    background-color: $white-light;

    &:hover,
    &:focus {
      background-color: darken($white-light, 3);
    }
  }

  &:nth-child(even) {
    background-color: $white;

    &:hover,
    &:focus {
      background-color: darken($white, 3);
    }
  }

  &:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &:nth-child(n + 2) {
    border-top: 0;
  }
}

.data-grid__row--narrow {
  @media screen and (max-width: 600px) {
    min-width: 25rem;
  }
}

.data-grid__row--passive {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($gray-light, 0.7);
  }
}

.data-grid__row--highlight {
  background-color: $highlight !important;
  border-color: $highlight !important;
  color: $white !important;

  .club {
    color: $white !important;
  }
}
