@import 'lib';

.status-color-bar {
  height: 1rem;
  width: 100%;
  box-shadow: 0 3px 7px 3px rgba($black, 0.16);
  transition: background-color ease-in-out 0.25s;
  display: none;
}

.status-color-bar--status-GOOD {
  display: block;
  background: $green;
}

.status-color-bar--status-WARNING {
  display: block;
  background: $yellow;
}

.status-color-bar--status-DANGER {
  display: block;
  background: $red;
}
