@import 'lib';

.data-grid--narrow-data {
  .data-grid__row {
    min-width: unset;
  }
}

.data-grid__wrapper {
  @media screen and (max-width: 600px) {
    padding: 0 0 0.75rem;
    overflow-x: auto;
  }
}

.data-grid__container {
  width: 100%;
  border-spacing: 0;

  @media screen and (max-width: 600px) {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
}
