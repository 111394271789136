@import 'lib';

body {
  font-family: $base-font-family;
  font-size: $base-font-size-rem * 1.125;
  line-height: $base-line-height * 1.125;
}

a {
  transition: all 200ms linear;
  color: $link-color;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $link-color-hover;
  }
}

// ########## Headings ########## //
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-family: $heading-font-family;
  font-weight: normal;
  line-height: 1.375;

  a {
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
  }
}

h1 {
  margin-bottom: 1.25rem;
  font-size: $font-size-heading-1;
  line-height: 1.2125;
  font-weight: 600;
}

h2 {
  margin-bottom: 1rem;
  font-size: $font-size-heading-2;
  font-weight: 500;
}

h3 {
  margin-bottom: 0.75rem;
  font-size: $font-size-heading-3;
}

h4 {
  margin-bottom: 0.5rem;
  font-size: $font-size-heading-4;
}

h5 {
  margin-bottom: 0.5rem;
  font-size: $font-size-heading-5;
}

h6 {
  font-size: 90%;
}

p {
  margin-top: 0;
}

p,
ul,
ol {
  + h2 {
    margin-top: 1.5rem;
  }

  + h3 {
    margin-top: 1rem;
  }

  + h4 {
    margin-top: 0.75rem;
  }
}

ul,
ol {
  ul,
  ol {
    margin: 0.375rem 0;
  }

  + p {
    margin-top: 1rem;
  }
}

blockquote,
q {
  border-left: 5px solid $body-color;
  margin: 2.5rem 0;
  padding: 0.5rem 1rem;
  quotes: '“' '”' '‘' '’';
  font-size: 1.5rem;

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }

  blockquote,
  q,
  p {
    border: 0;
    display: inline;
    margin: 0;
    padding: 0;
    color: inherit;
  }
}
