//
// @file
// This file includes all variables related to colors
//

$color-primary-0: #00539e; // Main Primary color */
$color-primary-1: #0a89fb;
$color-primary-2: #0072d8;
$color-primary-3: #004380;
$color-primary-4: #003360;

$black: #000000;
$black-gray: #111111;

$gray: #5c5c5c;
$gray-dark: #363636;

$white: #ffffff;
$white-light: #fafafa;
$white-dark: #d9d9d9;
$gray-light: #f2f2f2;

$yellow: #ffd300;
$yellow-bright: #ffd600;

$green: #63ce22;

$red: #ee1c25;
$purple: #89509a;
$orange: #f48f00;

$blue: $color-primary-2;
$blue-light: $color-primary-1;
$blue-medium: $color-primary-0;
$blue-dark: $color-primary-3;
$blue-very-dark: $color-primary-4;

$highlight: $orange;

$body-color: $black;
$body-background: $gray-light;

$link-color: $blue;
$link-color-hover: darken($blue, 20%);

$black-rgba-01: rgba($black, 0.1);
