@import 'lib';

.modal__no-overflow {
  overflow: hidden;

  &::after {
    content: '';
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba($black, 0.7);
  }
}

.modal {
  display: none;
  position: fixed;
  z-index: 11;
  top: 100%;
  left: 0;
  height: 100%;
  width: 100%;
  transition: top 0.25s ease-in-out;

  &.is-open {
    display: flex;
    top: 0;
  }
}

.modal--small {
  max-width: $small;
}

.modal__footer-container {
  .btn {
    width: calc(50% - 0.5rem);
    margin-bottom: 1.5rem;
  }
}

.modal__container {
  background: $white;
  border-radius: 3px;
}

.modal__header {
  top: 0;
  left: 0;
  padding: 0.75rem 1.5rem;
  width: 100%;
  position: absolute;
  border-radius: 3px 3px 0 0;
  background: $blue;
  color: $white;
}

.modal__content {
  padding-top: 4rem;
  padding-bottom: 1.5rem;
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
}

.modal__form-submit {
  margin-top: 1.5rem;
}
