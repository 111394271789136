@import 'lib';

.button {
  @include font-size(16px);
  background-color: transparent;
  color: $black;
  font-family: inherit;
  font-weight: 400;
  cursor: pointer;
  letter-spacing: normal;
  display: table;
  line-height: 1.2em;
  height: auto;
  border: 1px solid transparent;
  vertical-align: middle;
  transition: all 0.25s ease;
  -webkit-appearance: none;
  padding: 0.5rem 1rem;
  border-radius: 0.2em;
  width: 100%;
  box-sizing: border-box;
  text-align: center;

  @include medium-small {
    width: auto;
  }

  &:hover {
    box-shadow: inset 0 0 0 20rem rgba(0, 0, 0, 0.1);
  }
}

.button--blue {
  background-color: $blue;
  color: $white;

  &:hover,
  &:focus {
    color: $white;
    background-color: $blue-light;
  }
}

.button--green {
  color: $white;
  background-color: $green;
  border-color: $green;
}

.button--red {
  color: $white;
  background-color: $red;
  border-color: $red;
}

.button--l-spacing {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.button[disabled],
.button--disabled {
  cursor: default;
  opacity: 0.1;
}

.button--space-after {
  margin-bottom: 1.5rem;
}

.button--large {
  padding: 1rem 1.5rem;
}

.button--small {
  padding: 0.5rem;
}

.button--half {
  width: 45%;
}

.button--text-large {
  @include font-size(16px);

  @include medium-small {
    @include font-size(20px);
  }
}
