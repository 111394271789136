/// Mixin - mobile Breakpoint
/// Allows easier @include mobile {} syntax
@mixin mobile {
  @media screen and (max-width: $small - $max-width-deduction) {
    @content;
  }
}

/// Mixin - small Breakpoint
/// Allows easier @include small {} syntax
@mixin small {
  @media screen and (min-width: $small) {
    @content;
  }
}

@mixin medium-small {
  @media screen and (min-width: $medium-small) {
    @content;
  }
}

/// Mixin - medium Breakpoint
/// Allows easier @include medium {} syntax
@mixin medium {
  @media screen and (min-width: $medium) {
    @content;
  }
}

/// Mixin - medium-wide Breakpoint
/// Allows easier @include medium-wide {} syntax
@mixin medium-wide {
  @media screen and (min-width: $medium-wide) {
    @content;
  }
}

/// Mixin - wide Breakpoint
/// Allows easier @include wide {} syntax
@mixin wide {
  @media screen and (min-width: $wide) {
    @content;
  }
}

/// Mixin - extrawide Breakpoint
/// Allows easier @include extrawide {} syntax
@mixin extrawide {
  @media screen and (min-width: $extrawide) {
    @content;
  }
}
